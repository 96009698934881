import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 5%;
  width: ${isDesktop ? '50%' : '80%'};
`

export const Text = styled.span`
  font-size: 16px;
`

export const Heading = styled.span`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 1rem;
`
