import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem ${isDesktop ? '3rem' : '1.5rem'};
  z-index: 10;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const Nav = styled.div`
  display: flex;
  gap: 4rem;
`

export const Route = styled.span`
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
`
