import axios from 'axios'
import api from './api'

const url = `${process.env.REACT_APP_BASE_URL}`

export const signIn = async (email, password) => {
  let response
  try {
    response = await axios.post(url + '/api/v1/user/login/', {
      email,
      password,
    })
  } catch (error) {
    console.error(error)
    response = error.response
  }
  return response
}

export const signUp = async body => {
  let response

  try {
    response = await axios.post(url + '/api/v1/user/register/', body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } catch (error) {
    console.error(error)
    response = error.response
  }
  return response
}

export const activateEmail = async body => {
  try {
    const response = await axios.post(url + '/api/v1/user/activate/', body, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response
  } catch (error) {
    console.error(error)
  }
}

export const forgotPassword = async email => {
  let response

  try {
    response = await axios.post(
      url + '/api/v1/user/password/reset/',
      {
        email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (error) {
    console.error(error)
    response = error.response
  }
  return response
}

export const resetPassword = async body => {
  try {
    const response = await axios.post(
      url + '/api/v1/user/password-reset/confirm/',
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

export const refreshToken = async (refreshToken, accessToken) => {
  var headers = { 'Content-Type': 'application/json' }
  headers['SwapAppAuth'] = `Authorization ${accessToken}`
  let response
  try {
    response = await axios.post(
      url + '/api/v1/user/refresh-token/',
      {
        refresh: refreshToken,
      },
      {
        headers: headers,
      },
    )
  } catch (error) {
    console.error(error)
    response = error.response
  }
  return response
}

export const logout = async () => {
  try {
    const response = await api.post('/api/v1/user/logout/', {})
    return response
  } catch (error) {
    console.error(error)
  }
}
