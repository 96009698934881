/*eslint-disable*/
import { useEffect, useState } from 'react'
import Logo from '../../assets/Logo Black.webp'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { isDesktop } from 'react-device-detect'
import { signUp } from '../../api-handlers/auth'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {
  CheckboxLabel,
  ErrorText,
  FormContainer,
  Heading,
  Input,
  LinkText,
  Main,
  Text,
} from './styles'

const SignUp = () => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [agreeToUpdates, setAgreeToUpdates] = useState(false)
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [isPasswordTouched, setIsPasswordTouched] = useState(false)
  const [validationJSX, setValidationJSX] = useState(null)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'MilliCheck | Sign Up and Get Started '
  })

  useEffect(() => {
    if (isPasswordTouched) {
      const { jsx, allValid } = validatePassword(password)
      setValidationJSX(jsx)
      setIsPasswordValid(allValid)
    }
  }, [password, isPasswordTouched])

  const validatePassword = password => {
    const checks = {
      length: password.length >= 6 && password.length < 99,
      specialChar: /[=+\-^$*{}\[\]()`?!@#%&/\\><]/.test(password),
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
    }

    const allValid = Object.values(checks).every(Boolean)

    const jsx = (
      <div
        style={{
          backgroundColor: '#f0f8f8',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <div style={{ display: 'flex' }}>
          {checks.length ? (
            <CheckRoundedIcon style={{ color: 'green' }} />
          ) : (
            <CloseRoundedIcon style={{ color: 'red' }} />
          )}
          <span
            style={{
              marginLeft: '5px',
              color: checks.length ? 'green' : 'red',
            }}
          >
            Minimum length, which must be at least 6 characters but fewer than
            99 characters
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          {checks.specialChar ? (
            <CheckRoundedIcon style={{ color: 'green' }} />
          ) : (
            <CloseRoundedIcon style={{ color: 'red' }} />
          )}
          <span
            style={{
              marginLeft: '5px',
              color: checks.specialChar ? 'green' : 'red',
            }}
          >
            {
              'Require a special character: = + - ^ $ * [] {} () ? ! @ # % & /  >'
            }
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          {checks.uppercase ? (
            <CheckRoundedIcon style={{ color: 'green' }} />
          ) : (
            <CloseRoundedIcon style={{ color: 'red' }} />
          )}
          <span
            style={{
              marginLeft: '5px',
              color: checks.uppercase ? 'green' : 'red',
            }}
          >
            Require uppercase letters
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          {checks.lowercase ? (
            <CheckRoundedIcon style={{ color: 'green' }} />
          ) : (
            <CloseRoundedIcon style={{ color: 'red' }} />
          )}
          <span
            style={{
              marginLeft: '5px',
              color: checks.lowercase ? 'green' : 'red',
            }}
          >
            Require lowercase letters
          </span>
        </div>
      </div>
    )

    return { jsx, allValid }
  }

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match')
      return
    }
    setIsLoading(true)
    const body = {
      email: email,
      password: password,
      password1: confirmPassword,
      first_name: firstName,
      last_name: lastName,
      consent_to_updates: true,
      agree_to_terms: true,
    }
    try {
      const response = await signUp(body)
      if (response.status === 201) {
        navigate('/account-verification')
      } else {
        setError(response.data.error.email[0])
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return (
    <Main>
      <img
        style={{
          height: !isDesktop && '50px',
          width: !isDesktop && '190px',
        }}
        src={Logo}
        alt='Logo'
      />
      <FormContainer>
        <Heading>Sign Up</Heading>
        <Input
          style={{ margin: '0.5rem 0' }}
          placeholder='Email'
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Input
          style={{ margin: '0.5rem 0' }}
          placeholder='First name'
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
        />
        <Input
          style={{ margin: '0.5rem 0' }}
          placeholder='Last name'
          value={lastName}
          onChange={e => setLastName(e.target.value)}
        />
        <Input
          type={showPassword ? '' : 'password'}
          style={{ margin: '0.5rem 0' }}
          placeholder='Password'
          value={password}
          endAdornment={
            <InputAdornment position='end'>
              {showPassword ? (
                <VisibilityOffIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <VisibilityIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowPassword(true)}
                />
              )}
            </InputAdornment>
          }
          onChange={e => setPassword(e.target.value)}
          onFocus={() => setIsPasswordTouched(true)}
        />
        {isPasswordTouched && (
          <div style={{ marginTop: '0.5rem' }}>{validationJSX}</div>
        )}
        <Input
          type={showConfirmPassword ? '' : 'password'}
          style={{ margin: '0.5rem 0' }}
          placeholder='Confirm password'
          value={confirmPassword}
          endAdornment={
            <InputAdornment position='end'>
              {showConfirmPassword ? (
                <VisibilityOffIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowConfirmPassword(false)}
                />
              ) : (
                <VisibilityIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowConfirmPassword(true)}
                />
              )}
            </InputAdornment>
          }
          onChange={e => setConfirmPassword(e.target.value)}
        />
        <FormControl style={{ marginTop: '2rem' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeToUpdates}
                onChange={e => setAgreeToUpdates(e.target.checked)}
              />
            }
            label={
              <CheckboxLabel>
                I consent to receive valuable updates from Subtle Reply and
                acknowledge that I can unsubscribe at any time
              </CheckboxLabel>
            }
          />
        </FormControl>
        <FormControl style={{ marginTop: '0.5rem' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeToTerms}
                onChange={e => setAgreeToTerms(e.target.checked)}
              />
            }
            label={
              <CheckboxLabel>
                I agree to Subtle Reply <LinkText>Terms of Service</LinkText> &{' '}
                <LinkText>Privacy Policy</LinkText>
              </CheckboxLabel>
            }
          />
        </FormControl>
      </FormContainer>
      {error && <ErrorText>{error}</ErrorText>}
      <Button
        variant='contained'
        disabled={
          email.trim() === '' ||
          firstName.trim() === '' ||
          lastName.trim() === '' ||
          password.trim() === '' ||
          confirmPassword.trim() === '' ||
          !agreeToUpdates ||
          !agreeToTerms ||
          !isPasswordValid
        }
        sx={{
          textTransform: 'none',
          backgroundColor: '#002CB6',
          width: isDesktop ? '60%' : '80%',
          color: 'white',
          marginTop: '2rem',

          '&:hover': {
            backgroundColor: '#0038E5',
          },

          '&:disabled': {
            backgroundColor: '#002CB6',
            color: 'white',
            opacity: 0.5,
          },
        }}
        onClick={handleSignUp}
      >
        {isLoading ? <CircularProgress sx={{ color: 'white' }} /> : 'Sign Up'}
      </Button>
      <Text>
        Already have an account?{' '}
        <LinkText onClick={() => navigate('/sign-in')}>Sign in</LinkText>
      </Text>
    </Main>
  )
}

export default SignUp
