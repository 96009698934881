import api from './api'

export const getLatestWinningNumbers = async () => {
  try {
    const response = await api.get('/api/v1/lottery/latest')
    return response
  } catch (error) {
    console.error(error)
  }
}

export const listSavedTickets = async token => {
  try {
    const response = await api.get('/api/v1/lottery/tickets/')
    return response
  } catch (error) {
    console.error(error)
  }
}

export const saveTicket = async body => {
  try {
    const response = await api.post('/api/v1/lottery/tickets/', body)
    return response
  } catch (error) {
    console.error(error)
  }
}

export const editTicket = async (ticketInfo, id) => {
  try {
    const response = await api.put(`/api/v1/lottery/tickets/${id}/`, ticketInfo)
    return response
  } catch (error) {
    console.error(error)
  }
}

export const deleteTicket = async id => {
  try {
    const response = await api.delete(`/api/v1/lottery/tickets/${id}/`)
    return response
  } catch (error) {
    console.error(error)
  }
}

export const lotteryWidget = async id => {
  try {
    const response = await api.get(
      `/api/v1/lottery/user-megaball-lottery-widget/`,
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

export const resendLotteryEmail = async id => {
  try {
    const response = await api.post(`/api/v1/lottery/resend-user-email/`)
    return response
  } catch (error) {
    console.error(error)
  }
}
