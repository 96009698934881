import { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import Logo from '../../assets/Logo.png'
import WinningNumber from '../../components/WinningNumber'
import { Button, CircularProgress, Tooltip } from '@mui/material'
import CheckMark from '../../assets/check-mark.png'
import { isDesktop } from 'react-device-detect'
import SavedNumber from '../../components/SavedNumber'
import SnackbarStyled from '../../components/snackbar'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  getLatestWinningNumbers,
  listSavedPowerballTickets,
  lotteryWidget,
  saveTicket,
} from '../../api-handlers/powerball'
import { getWidget } from '../../utils/helperFunctions'
import { useLocation } from 'react-router-dom'
import {
  Container,
  DigitInputField,
  ErrorText,
  FutureSection,
  Heading,
  InputFieldsContainer,
  JackkpotContainer,
  LotteryNumbers,
  Main,
  NumbersRow,
  Row,
  Subheading,
  Text,
  TimeBox,
  TimeContainer,
  Timer,
  WinningNumbersSection,
} from './styles'

const Powerball = () => {
  const [lotteryNumber, setLotteryNumber] = useState(['', '', '', '', '', ''])
  const [isInputValid, setIsInputValid] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ])
  const [lotteryData, setLotteryData] = useState()
  const [winningNumber, setWinningNumber] = useState([])
  const [drawing_date, setDrawingDate] = useState()
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const [error, setError] = useState('')
  const [savedTickets, setSavedTickets] = useState(null)
  const [toast, setToast] = useState({
    open: false,
    message: 'Ticket created successfully',
    severity: 'success',
  })
  const [reload, setReload] = useState(false)
  const [eventCode, setEventCode] = useState()
  const [isLoding, setIsLoading] = useState(false)

  const inputRefs = useRef([])
  const location = useLocation()

  useEffect(() => {
    document.title = 'MilliCheck | Power Ball Lottery Board'
  })

  useEffect(() => {
    getSavedTickets()
    getWinningNumbers()
    getLotteryWidget()
  }, [reload])

  useEffect(() => {
    const arr = lotteryData?.number_set.split(' ')
    const cleanedArray = arr?.map(str => str.replace(/x$/, ''))
    setWinningNumber(cleanedArray)

    const isoDate = lotteryData?.drawing_date
    const formattedDate = isoDate?.substring(0, 10)
    setDrawingDate(formattedDate)

    const calculateTimeLeft = targetDate => {
      if (!targetDate) {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        })
      } else {
        const now = new Date().toLocaleString('en-US', {
          timeZone: 'America/New_York',
        })
        const now2 = new Date(now).getTime()
        const parts = targetDate.split(/[-T:.Z]/)
        const year = parseInt(parts[0])
        const month = parseInt(parts[1]) - 1
        const day = parseInt(parts[2])
        const hour = parseInt(parts[3])
        const minute = parseInt(parts[4])
        const second = parseInt(parts[5])

        const utcDate = new Date(year, month, day, hour, minute, second)

        const timestamp = utcDate.getTime()

        const distance = timestamp - now2

        if (distance > 0) {
          setTimeLeft({
            days: Math.floor(distance / (1000 * 60 * 60 * 24)),
            hours: Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
            ),
            minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((distance % (1000 * 60)) / 1000),
          })
        } else {
          setTimeLeft({
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          })
        }
      }
    }

    calculateTimeLeft(lotteryData?.next_drawing_date)

    const interval = setInterval(
      () => calculateTimeLeft(lotteryData?.next_drawing_date),
      1000,
    )

    return () => clearInterval(interval)
  }, [lotteryData])

  const getSavedTickets = async () => {
    setIsLoading(true)
    const tokens = JSON.parse(localStorage.getItem('tokens'))
    const response = await listSavedPowerballTickets(tokens?.access)
    setSavedTickets(response?.data)
  }

  const getWinningNumbers = async () => {
    const response = await getLatestWinningNumbers()
    if (response) {
      setLotteryData(response.data[0])
    }
  }

  const getLotteryWidget = async () => {
    const response = await lotteryWidget()
    setEventCode(response?.event_code)
    setIsLoading(false)
  }

  const validateInput = (value, index) => {
    const num = parseInt(value, 10)
    let valid = true

    if (index === lotteryNumber.length - 1) {
      valid = num >= 1 && num <= 26
    } else {
      valid = num >= 1 && num <= 69
    }

    return valid
  }

  const handleInputChange = (event, index) => {
    setError('')
    let inputDigit = event.target.value

    if (inputDigit.length === 2 && index < lotteryNumber.length - 1) {
      // Move focus to the next input field if there are two digits
      inputRefs.current[index + 1]?.focus()
    }

    if (inputDigit > 99) return false

    inputDigit = inputDigit.replace(/^0+(?=\d)/, '')

    const updatedLotteryNumber = [...lotteryNumber]
    updatedLotteryNumber[index] = inputDigit

    const updatedIsInputValid = Array(updatedLotteryNumber.length).fill(true)

    const numberOccurrences = {}

    updatedLotteryNumber.forEach((num, idx) => {
      if (num !== '' && idx !== updatedLotteryNumber.length - 1) {
        const normalizedNum = num.replace(/^0+(?=\d)/, '')

        if (numberOccurrences[normalizedNum]) {
          numberOccurrences[normalizedNum].push(idx)
        } else {
          numberOccurrences[normalizedNum] = [idx]
        }

        updatedIsInputValid[idx] = validateInput(normalizedNum, idx)
      }
    })

    Object.values(numberOccurrences).forEach(indices => {
      if (indices.length > 1) {
        indices.forEach(i => {
          updatedIsInputValid[i] = false
        })
      }
    })

    setLotteryNumber(updatedLotteryNumber)
    setIsInputValid(updatedIsInputValid)

    const hasDuplicates = Object.values(numberOccurrences).some(
      indices => indices.length > 1,
    )
    if (hasDuplicates) setError('Duplicate values are not allowed.')
    validateNumberOnChange(updatedLotteryNumber)
  }

  const validateNumberOnChange = arr => {
    for (let i = 0; i < 5; i++) {
      if (arr[i] !== '' && (arr[i] < 1 || arr[i] > 69)) {
        setError('Numbers should be between 1 and 69')
      }
    }

    if (arr[5] !== '' && (arr[5] < 1 || arr[5] > 26)) {
      setError('Power Ball number should be between 1 and 26')
    }
  }

  const validateLotteryNumber = arr => {
    for (let i = 0; i < 5; i++) {
      if (arr[i] < 1 || arr[i] > 69) {
        setError('Numbers should be between 1 and 69')
        return false
      }
    }

    if (arr[5] < 1 || arr[5] > 26) {
      setError('Power Ball number should be between 1 and 26')
      return false
    }

    return true
  }

  const handleSaveTicket = async () => {
    const isValid = validateLotteryNumber(lotteryNumber)
    const tokens = JSON.parse(localStorage.getItem('tokens'))

    const ticketInfo = {
      first_number: lotteryNumber[0],
      second_number: lotteryNumber[1],
      third_number: lotteryNumber[2],
      fourth_number: lotteryNumber[3],
      fifth_number: lotteryNumber[4],
      power_ball: lotteryNumber[5],
    }

    if (isValid) {
      const response = await saveTicket(ticketInfo, tokens?.access)
      setToast({
        ...toast,
        open: true,
        message: response?.message,
        severity: response?.message?.includes('success') ? 'success' : 'info',
      })
      getSavedTickets()
      setLotteryNumber(['', '', '', '', '', ''])
      setReload(!reload)
    }
  }

  const handleSnackbarClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToast({ ...toast, open: false })
  }

  return (
    <>
      <SnackbarStyled
        message={toast.message}
        onClose={handleSnackbarClose}
        severity={toast.severity}
        open={toast.open}
      />
      <Main>
        <Header logo={Logo} />
        <Container
          style={{
            minHeight: isLoding && '700px',
            justifyContent: isLoding && 'center',
            alignItems: isLoding && 'center',
          }}
        >
          {!isDesktop && (
            <Heading
              style={{
                alignSelf: 'center',
                marginTop: '2rem',
                marginBottom: '-2rem',
                fontSize: '20px',
              }}
            >
              Power Ball Lottery Board
            </Heading>
          )}
          {isLoding ? (
            <CircularProgress />
          ) : (
            <>
              <Row style={{ marginTop: '4rem' }}>
                <WinningNumbersSection>
                  <Subheading
                    style={{
                      color:
                        location.pathname.includes('/powerball') && 'black',
                    }}
                  >
                    LAST WINNING NUMBERS
                  </Subheading>
                  <NumbersRow>
                    {winningNumber?.map((number, index) => (
                      <WinningNumber
                        key={index}
                        number={number}
                        index={index}
                      />
                    ))}
                  </NumbersRow>
                  <Text
                    style={{
                      marginTop: isDesktop ? '2rem' : '1rem',
                      color: location.pathname.includes('/powerball')
                        ? 'black'
                        : 'white',
                    }}
                  >
                    Last draw date : {drawing_date}
                  </Text>
                </WinningNumbersSection>
                <FutureSection>
                  <Text style={{ fontWeight: '500', color: 'black' }}>
                    Next draw in:
                  </Text>
                  <Timer>
                    <TimeContainer>
                      <TimeBox>
                        <Subheading>{timeLeft.days}</Subheading>
                      </TimeBox>
                      <Text>Days</Text>
                    </TimeContainer>
                    <TimeContainer>
                      <TimeBox>
                        <Subheading>{timeLeft.hours}</Subheading>
                      </TimeBox>
                      <Text>Hours</Text>
                    </TimeContainer>
                    <TimeContainer>
                      <TimeBox>
                        <Subheading>{timeLeft.minutes}</Subheading>
                      </TimeBox>
                      <Text>Minutes</Text>
                    </TimeContainer>
                    <TimeContainer>
                      <TimeBox>
                        <Subheading>{timeLeft.seconds}</Subheading>
                      </TimeBox>
                      <Text>Seconds</Text>
                    </TimeContainer>
                  </Timer>
                  <JackkpotContainer>
                    <Text
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        color: 'black',
                        fontWeight: '500',
                      }}
                    >
                      Winning prize :{'  '}
                      <Text
                        style={{
                          fontSize: '25px',
                          fontWeight: '700',
                          color: 'black',
                        }}
                      >
                        {lotteryData?.next_jackpot}
                      </Text>
                    </Text>
                    <Subheading style={{ color: '#002CB6' }}>
                      GOOD LUCK!
                    </Subheading>
                  </JackkpotContainer>
                </FutureSection>
              </Row>
              <Row
                style={{
                  marginTop: isDesktop ? '4rem' : '2rem',
                  marginBottom: '4rem',
                  backgroundColor: '#F2F2F2',
                  borderRadius: '10px',
                  padding: isDesktop ? '2rem' : '2rem 1rem',
                }}
              >
                <LotteryNumbers>
                  <Subheading style={{ fontWeight: '700', color: 'black' }}>
                    Add your Lottery Numbers
                    <Tooltip
                      arrow
                      title='Numbers should be between 1-69, Megaball number should be between 1-26, Duplicate numbers are not allowed'
                    >
                      <InfoOutlinedIcon
                        style={{
                          color: 'grey',
                          cursor: 'pointer',
                          marginLeft: '1rem',
                        }}
                      />
                    </Tooltip>
                  </Subheading>
                  <InputFieldsContainer>
                    {lotteryNumber.map((digit, index) => (
                      <DigitInputField
                        style={{
                          color:
                            index === lotteryNumber.length - 1
                              ? 'white'
                              : 'black',
                          backgroundColor:
                            index === lotteryNumber.length - 1
                              ? '#C8102E'
                              : 'white',
                          border: isInputValid[index]
                            ? index === lotteryNumber.length - 1
                              ? '1px solid #D8AA09'
                              : '1px solid #6D6D6D'
                            : '1px solid red',
                          marginLeft:
                            index === lotteryNumber.length - 1 && '1rem',
                        }}
                        key={index}
                        ref={el => (inputRefs.current[index] = el)}
                        value={digit}
                        inputMode='numeric'
                        inputProps={{ maxLength: 2 }}
                        onChange={e => handleInputChange(e, index)}
                        onKeyDown={e => {
                          if (
                            !/^[0-9]$/.test(e.key) && // Allow digits
                            e.key !== 'Backspace' && // Allow backspace
                            e.key !== 'Delete' && // Allow delete
                            e.key !== 'ArrowLeft' && // Allow left arrow
                            e.key !== 'ArrowRight' // Allow right arrow
                          ) {
                            e.preventDefault() // Prevent any other input
                          }
                          if (
                            e.key === 'Backspace' &&
                            digit === '' &&
                            index > 0
                          ) {
                            e.preventDefault() // Prevent the default backspace action
                            inputRefs.current[index - 1]?.focus() // Focus the previous input
                          }
                        }}
                      />
                    ))}
                    {!(
                      lotteryNumber.some(digit => digit === '') ||
                      isInputValid.some(valid => !valid)
                    ) && (
                      <Button
                        variant='contained'
                        disabled={
                          lotteryNumber.some(digit => digit === '') ||
                          isInputValid.some(valid => !valid)
                        }
                        sx={{
                          textTransform: 'none',
                          backgroundColor: '#17A31C',
                          color: 'white',
                          marginLeft: isDesktop ? '2rem' : '1rem',
                          width: '25px',
                          height: '25px',
                          minWidth: 'unset',

                          '&:hover': {
                            backgroundColor: '#17A31C',
                            opacity: 0.75,
                          },

                          '&:disabled': {
                            backgroundColor: '#17A31C',
                            color: 'white',
                            opacity: 0.5,
                          },
                        }}
                      >
                        <img
                          style={{
                            height: '15px',
                            width: '15px',
                          }}
                          src={CheckMark}
                          alt='Check Mark'
                        />
                      </Button>
                    )}
                  </InputFieldsContainer>
                  {error && <ErrorText>{error}</ErrorText>}
                  <Button
                    variant='contained'
                    disabled={
                      lotteryNumber.some(digit => digit === '') ||
                      isInputValid.some(valid => !valid)
                    }
                    sx={{
                      textTransform: 'none',
                      backgroundColor: '#002CB6',
                      color: 'white',
                      width: isDesktop ? '40%' : '50%',
                      marginTop: '2rem',

                      '&:hover': {
                        backgroundColor: '#0038E5',
                      },

                      '&:disabled': {
                        backgroundColor: '#002CB6',
                        color: 'white',
                        opacity: 0.5,
                      },
                    }}
                    onClick={handleSaveTicket}
                  >
                    Save Ticket
                  </Button>
                </LotteryNumbers>
                {eventCode === '203' ? (
                  savedTickets && savedTickets.length > 0 ? (
                    <div
                      style={{
                        width: '100%',
                        height: '270px',
                        overflowY: 'auto',
                      }}
                    >
                      <Heading>Your Saved Power Ball Lottery Numbers</Heading>
                      {savedTickets.map(ticketInfo => {
                        return (
                          <SavedNumber
                            key={ticketInfo.ticket_id}
                            payload={ticketInfo}
                            reload={reload}
                            setReload={setReload}
                          />
                        )
                      })}
                    </div>
                  ) : (
                    <Text
                      style={{
                        color: '#002CB6',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        width: '100%',
                        marginLeft: '4rem',
                      }}
                    >
                      No saved numbers
                    </Text>
                  )
                ) : (
                  getWidget(eventCode)
                )}
              </Row>
            </>
          )}
        </Container>
      </Main>
    </>
  )
}

export default Powerball
