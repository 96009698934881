import { useEffect, useState } from 'react'
import Logo from '../../assets/Logo Black.webp'
import { Button, CircularProgress, InputAdornment } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { isDesktop } from 'react-device-detect'
import { signIn } from '../../api-handlers/auth'
import {
  ErrorText,
  FormContainer,
  Heading,
  Input,
  LinkText,
  LinkTextContainer,
  Main,
  Text,
} from './styles'

const SignIn = () => {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'MilliCheck | Login to Millicheck'
  })

  const handleSignIn = async () => {
    setIsLoading(true)
    try {
      const response = await signIn(userName, password)
      if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify(response.data.data.user))
        localStorage.setItem(
          'tokens',
          JSON.stringify({
            refresh: response.data.data.refresh,
            access: response.data.data.access,
          }),
        )
        navigate('/megamillions')
      } else {
        setError(response.data.error)
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return (
    <Main>
      <img
        style={{
          // margin: '3rem 0',
          height: '50px',
          width: '190px',
        }}
        src={Logo}
        alt='Logo'
      />
      <FormContainer>
        <Heading>Sign In</Heading>
        <Input
          style={{ margin: '1rem 0' }}
          placeholder='Email'
          value={userName}
          onChange={e => setUserName(e.target.value)}
        />
        <Input
          placeholder='Password'
          type={showPassword ? '' : 'password'}
          value={password}
          endAdornment={
            <InputAdornment position='end'>
              {showPassword ? (
                <VisibilityOffIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <VisibilityIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowPassword(true)}
                />
              )}
            </InputAdornment>
          }
          onChange={e => setPassword(e.target.value)}
        />
        <LinkTextContainer>
          <ErrorText>{error ? error : ' '}</ErrorText>
          <LinkText
            style={{ alignSelf: 'flex-end', marginTop: '5px' }}
            onClick={() => navigate('/forgot-password')}
          >
            Forgot Password?
          </LinkText>
        </LinkTextContainer>
      </FormContainer>
      <Button
        variant='contained'
        disabled={userName.trim() === '' || password.trim() === ''}
        sx={{
          textTransform: 'none',
          backgroundColor: '#002CB6',
          width: isDesktop ? '60%' : '80%',
          color: 'white',
          marginTop: '1rem',

          '&:hover': {
            backgroundColor: '#0038E5',
          },

          '&:disabled': {
            backgroundColor: '#002CB6',
            color: 'white',
            opacity: 0.5,
          },
        }}
        onClick={handleSignIn}
      >
        {isLoading ? <CircularProgress sx={{ color: 'white' }} /> : 'Sign In'}
      </Button>
      <Text>
        Don't have an account?{' '}
        <LinkText onClick={() => navigate('/sign-up')}>Sign Up</LinkText>
      </Text>
    </Main>
  )
}

export default SignIn
