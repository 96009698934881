import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${!isDesktop && 'center'};
  width: ${isDesktop ? '30%' : '100%'};
  gap: ${!isDesktop && '2rem'};
  text-align: ${!isDesktop && 'center'};
`

export const Title = styled.div`
  font-size: ${isDesktop ? '25px' : '20px'};
  font-weight: bold;
`

export const Description = styled.div`
  font-size: 16px;
`
