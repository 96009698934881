import Task from "../assets/Task.png";
import Email from "../assets/Email.png";
import { useLocation } from "react-router-dom";
import { resendPowerballEmail } from "../api-handlers/powerball";
import { resendLotteryEmail } from "../api-handlers/megamillions";
import SnackbarStyled from "./snackbar";
import { useState } from "react";
import { EmailSection, Heading, Main, Text } from "./styles/NoTicketsEmail";

const NoTicketsEmail = () => {
  const [toast, setToast] = useState({
    open: false,
    message: "Email Sent",
    severity: "success",
  });

  const location = useLocation();

  const handleResendEmail = async () => {
    let response;
    if (location.pathname === "/powerball") {
      response = await resendPowerballEmail();
      setToast({
        ...toast,
        message:
          response?.detail ? "Your email has been successfully sent! Please check your inbox for your lottery results. If you still don’t receive it, be sure to check your spam folder or try again." : "Something went wrong!",
        open: true,
      });
    } else {
      response = await resendLotteryEmail();
      setToast({
        ...toast,
        message:
          response?.detail ? "Your email has been successfully sent! Please check your inbox for your lottery results. If you still don’t receive it, be sure to check your spam folder or try again." : "Something went wrong!",
        open: true,
      });
    }
  };

  const handleSnackbarClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToast({ ...toast, open: false });
  };

  return (
    <>
      <SnackbarStyled
        message={toast.message}
        onClose={handleSnackbarClose}
        severity={toast.severity}
        open={toast.open}
        autoHideDuration={5000}
      />
      <Main>
        <Heading>No tickets were saved for this draw. </Heading>
        <img style={{ height: "100px", width: "100px" }} src={Task} alt="Img" />
        <Text>Save your numbers now to participate in the next draw.</Text>
        <EmailSection>
          <img
            style={{ height: "50px", width: "50px" }}
            src={Email}
            alt="Img"
          />
          <Text style={{ color: "black" }}>
            Haven’t received the email for your previous tickets? <br />
            <span
              style={{ color: "#002CB6", cursor: "pointer" }}
              onClick={handleResendEmail}
            >
              Click here
            </span>{" "}
            so we can send it to you again.
          </Text>
        </EmailSection>
      </Main>
    </>
  );
};

export default NoTicketsEmail;
