import Project from '../assets/Project.png'
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded'
import { Heading, Main, Text } from './styles/StartAddingNumbers'

const StartAddingNumbers = () => {
  return (
    <Main>
      <Heading>Start adding numbers!</Heading>
      <img
        style={{ height: '100px', width: '100px' }}
        src={Project}
        alt='Img'
      />
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <TrendingFlatRoundedIcon
          sx={{
            fontSize: '40px',
            marginTop: '0.2rem',
            color: '#6d6d6d',
            transform: 'rotate(180deg)',
          }}
        />
        <Text>Add your lottery numbers</Text>
      </div>
    </Main>
  )
}

export default StartAddingNumbers
