import Email from '../assets/Email.png'
import { resendPowerballEmail } from '../api-handlers/powerball'
import { resendLotteryEmail } from '../api-handlers/megamillions'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import SnackbarStyled from './snackbar'
import { Heading, Main, Text, TextSection } from './styles/ResultsEmail'

const ResultsEmail = () => {
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  const location = useLocation()

  const handleResendEmail = async () => {
    let response
    if (location.pathname === '/powerball') {
      response = await resendPowerballEmail()
      setToast({
        ...toast,
        message:
          response?.detail ? "Your email has been successfully sent! Please check your inbox for your lottery results. If you still don’t receive it, be sure to check your spam folder or try again." : "Something went wrong!",
        open: true,
      });
    } else {
      response = await resendLotteryEmail()
      setToast({
        ...toast,
        message:
          response?.detail ? "Your email has been successfully sent! Please check your inbox for your lottery results. If you still don’t receive it, be sure to check your spam folder or try again." : "Something went wrong!",
        open: true,
      });
    }
  }

  const handleSnackbarClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToast({ ...toast, open: false })
  }
  return (
    <>
      <SnackbarStyled
        message={toast.message}
        onClose={handleSnackbarClose}
        severity={toast.severity}
        open={toast.open}
      />
      <Main>
        <Heading>Your saved Lottery Numbers</Heading>
        <TextSection>
          <img
            style={{ height: '100px', width: '100px' }}
            src={Email}
            alt='img'
          />
          <Text>
            <Text style={{ color: 'Green' }}>
              We have sent you an email with your results. Please check your
              email.
            </Text>
            <br />
            <br />
            <br />
            Haven’t received the email?
            <br />{' '}
            <span
              style={{ color: '#002CB6', cursor: 'pointer' }}
              onClick={handleResendEmail}
            >
              Click here
            </span>{' '}
            so we can send it to you again.
          </Text>
        </TextSection>
      </Main>
    </>
  )
}

export default ResultsEmail
