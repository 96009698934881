/* eslint-disable */
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { activateEmail } from '../../api-handlers/auth'

const EmailAcitvation = () => {
  const { uid, token } = useParams()

  const navigate = useNavigate()

  useEffect(() => {
    const handleEmailActivation = async () => {
      const body = {
        uid64: uid,
        token: token,
      }
      try {
        const response = await activateEmail(body)
        if (response?.status === 200) {
          navigate('/verification')
        } else {
          navigate('/verification', {
            state: { error: 'Email activation failed' },
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
    handleEmailActivation()
  }, [])
  return <div></div>
}

export default EmailAcitvation
