import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: ${isDesktop ? '100%' : '80%'};
  text-align: center;
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  margin-left: ${isDesktop && '4rem'};
`

export const Heading = styled.span`
  font-size: 26px;
  font-weight: 500;
  color: #ab0303;
`

export const Text = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #6d6d6d;
`
