import api from './api'

export const getLatestWinningNumbers = async () => {
  try {
    const response = await api.get('/api/v1/powerball/latest')
    return response
  } catch (error) {
    console.error(error)
  }
}

export const listSavedPowerballTickets = async token => {
  try {
    const response = await api.get('/api/v1/powerball/tickets/')
    return response
  } catch (error) {
    console.error(error)
  }
}

export const saveTicket = async body => {
  try {
    const response = await api.post('/api/v1/powerball/tickets/', body)
    return response
  } catch (error) {
    console.error(error)
  }
}

export const editPowerballTicket = async (ticketInfo, id) => {
  try {
    const response = await api.put(
      `/api/v1/powerball/tickets/${id}/`,
      ticketInfo,
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

export const deletePowerballTicket = async id => {
  try {
    const response = await api.delete(`/api/v1/powerball/tickets/${id}/`)
    return response
  } catch (error) {
    console.error(error)
  }
}

export const lotteryWidget = async id => {
  try {
    const response = await api.get(
      `/api/v1/powerball/user-powerball-lottery-widget/`,
    )
    return response
  } catch (error) {
    console.error(error)
  }
}

export const resendPowerballEmail = async id => {
  try {
    const response = await api.post(`/api/v1/powerball/resend-user-email/`)
    return response
  } catch (error) {
    console.error(error)
  }
}
