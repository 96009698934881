import LotteryTime from '../components/LotteryTime'
import NoTickets from '../components/NoTickets'
import NoTicketsEmail from '../components/NoTicketsEmail'
import ResultsEmail from '../components/ResultsEmail'
import StartAddingNumbers from '../components/StartAddingNumbers'
import UnderProcess from '../components/UnderProcess'

export const getWidget = eventCode => {
  let widget
  switch (eventCode) {
    case '205':
      widget = <NoTickets />
      break
    case '204':
      widget = <NoTicketsEmail />
      break
    case '202':
      widget = <StartAddingNumbers />
      break
    case '103':
      widget = <LotteryTime />
      break
    case '102':
      widget = <ResultsEmail />
      break
    case '101':
      widget = <UnderProcess />
      break
    default:
      widget = ''
  }
  return widget
}
