import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

export const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 5px;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
`

export const DigitInputField = styled.input`
  height: ${isDesktop ? '40px' : '30px'};
  width: ${isDesktop ? '40px' : '30px'};
  font-size: ${isDesktop ? '16px' : '12px'};
  text-align: center;
  padding: 0;
  border-radius: 5px;
  border: 1px solid #6d6d6d;

  &:disabled {
    border: none !important;
  }
`

export const ErrorText = styled.span`
  font-size: 16px;
  color: red;
  font-weight: 500;
`

export const StyledInfoIcon = styled(InfoOutlinedIcon)`
  color: #6d6d6d;
  cursor: pointer;

  &:hover {
    color: #002cb6;
  }
`

export const StyledEditIcon = styled(EditRoundedIcon)`
  color: #6d6d6d;
  cursor: pointer;

  &:hover {
    color: green;
  }
`

export const StyledDeleteIcon = styled(DeleteRoundedIcon)`
  color: red;
  cursor: pointer;

  &:hover {
    color: red;
  }
`

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
`
