import { Alert, Snackbar } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import styled from 'styled-components'

const StyledAlert = styled(Alert)`
  /* color: white; */
  /* background-color: #35ad57; */
`

const ColoredAlertCheckIcon = () => (
  <CheckCircleIcon style={{ color: 'white' }} />
)
const ColoredAlertErrorIcon = () => <CancelIcon style={{ color: 'white' }} />
const ColoredAlertWarningIcon = () => (
  <ErrorOutlineIcon style={{ color: 'white' }} />
)

const SnackbarStyled = ({
  open,
  onClose,
  message,
  severity,
  autoHideDuration,
}) => {
  const vertical = 'top'
  const horizontal = 'right'
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration || 3000}
        onClose={onClose}
        anchorOrigin={{ vertical, horizontal }}
        style={{width: "500px"}}
      >
        <StyledAlert
          onClose={onClose}
          severity={severity}
          variant='filled'
          iconMapping={{
            success: <ColoredAlertCheckIcon />,
            error: <ColoredAlertErrorIcon />,
            warning: <ColoredAlertWarningIcon />,
          }}
        >
          {message}
        </StyledAlert>
      </Snackbar>
    </div>
  )
}

export default SnackbarStyled
