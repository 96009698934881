import { useLocation } from 'react-router-dom'
import { Main, Number } from './styles/WinningNumber'

const WinningNumber = ({ number, index }) => {
  const location = useLocation()

  return (
    <Main
      style={{
        backgroundColor:
          index === 5
            ? location.pathname === '/powerball'
              ? '#C8102E'
              : '#D8AA09'
            : 'white',
        color: index === 5 ? 'white' : 'black',
        boxShadow:
          index === 5 &&
          location.pathname !== '/powerball' &&
          'box-shadow: 0px 10px 8px 0px #FFFFFF80 inset,0px -10px 10px 0px #00000026 inset,0px 2px 5px 0px #00000033,0px 0px 25px 5px #FFFFFF40 inset',
      }}
    >
      <Number>{number}</Number>
    </Main>
  )
}

export default WinningNumber
