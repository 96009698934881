import { Button } from '@mui/material'
import LogoBlack from '../../assets/Logo Black.webp'
import InstantLotteryResultsIcon from '../../assets/Instant Lottery Results Icon.png'
import ComprehensiveDrawHistoryIcon from '../../assets/Comprehensive Draw History Icon.png'
import PersonalizedLotteryCheckIcon from '../../assets/Personalized Lottery Checker Icon.png'
import SecureAndConfidentialIcon from '../../assets/Secure and Confidential Icon.png'
import UserFriendlyInterfaceIcon from '../../assets/User-Friendly Interface Icon.png'
import WinningNotificationsIcon from '../../assets/Winning Notifications Icon.png'
import { useNavigate } from 'react-router-dom'
import Feature from '../../components/Feature'
import { featuresA, featuresB, stepsA, stepsB } from '../../utils/constants'
import SignUpIcon from '../../assets/Sign up Icon.svg'
import EnterYourLotteryNumbersIcon from '../../assets/Enter Your Lottery Numbers Icon.png'
import CheckLotteryResultsIcon from '../../assets/Check Lottery Results Icon.svg'
import TrackYourWinningsIcon from '../../assets/Track Your Winnings Icon.png'
import Step from '../../components/Step'
import Ball1 from '../../assets/Ball1.png'
import Ball2 from '../../assets/Ball2.png'
import Ball3 from '../../assets/Ball3.png'
import Ball4 from '../../assets/Ball4.png'
import FooterIllustration from '../../assets/Footer Illustration.webp'
import { isDesktop } from 'react-device-detect'
import Header from '../../components/Header'
import { useEffect, useState } from 'react'
import { listSavedTickets } from '../../api-handlers/megamillions'
import { listSavedPowerballTickets } from '../../api-handlers/powerball'
import SavedNumber from '../../components/SavedNumber'
import {
  BackgroundSection,
  CommunitySection,
  Features,
  Footer,
  FooterTextContainer,
  Heading,
  ItalicText,
  Main,
  Row,
  Subheading,
  Text,
  TicketsContainer,
  TicketsHeader,
  WelcomeSection,
} from './styles'

const Home = () => {
  const [savedLotteryTickets, setSavedLotteryTickets] = useState([])
  const [savedPowerballTickets, setSavedPowerballTickets] = useState([])

  const navigate = useNavigate()

  const featureAIcons = [
    InstantLotteryResultsIcon,
    PersonalizedLotteryCheckIcon,
    WinningNotificationsIcon,
  ]
  const featureBIcons = [
    UserFriendlyInterfaceIcon,
    ComprehensiveDrawHistoryIcon,
    SecureAndConfidentialIcon,
  ]
  const stepsAIcons = [SignUpIcon, EnterYourLotteryNumbersIcon]
  const stepsBIcons = [CheckLotteryResultsIcon, TrackYourWinningsIcon]
  const stepsABalls = [Ball1, Ball2]
  const stepsBBalls = [Ball3, Ball4]

  const storedUser = localStorage.getItem('user')

  useEffect(() => {
    document.title = 'MilliCheck | Home'
  })

  useEffect(() => {
    getSavedLotteryTickets()
    getSavedPowerballTickets()
  }, [])

  const getSavedLotteryTickets = async () => {
    const tokens = JSON.parse(localStorage.getItem('tokens'))
    const response = await listSavedTickets(tokens?.access)
    setSavedLotteryTickets(response?.data)
  }

  const getSavedPowerballTickets = async () => {
    const tokens = JSON.parse(localStorage.getItem('tokens'))
    const response = await listSavedPowerballTickets(tokens?.access)
    setSavedPowerballTickets(response?.data)
  }

  return (
    <Main>
      <BackgroundSection>
        <Header logo={LogoBlack} />
        <Row>
          <WelcomeSection
            style={{ paddingBottom: isDesktop ? '10rem' : '0rem' }}
          >
            <h1 style={{ display: 'none' }}>Welcome to MilliCheck</h1>{' '}
            {/* Hidden for SEO purposes */}
            <Heading>
              Welcome to{' '}
              <Heading style={{ color: '#002CB6' }}>MilliCheck </Heading>
            </Heading>
            <ItalicText>
              Check lottery results and track your winnings effortlessly with
              MilliCheck.{' '}
            </ItalicText>
            <Text style={{ marginTop: '2rem' }}>
              Your go-to platform for a seamless, enjoyable lottery experience.
              Explore now!
            </Text>
            <Button
              variant='contained'
              sx={{
                textTransform: 'none',
                backgroundColor: '#002CB6',
                width: '40%',
                color: 'white',
                marginTop: '2rem',
                alignSelf: !isDesktop && 'center',

                '&:hover': {
                  backgroundColor: '#0038E5',
                },
              }}
              onClick={() => navigate('/sign-in')}
            >
              Start exploring
            </Button>
          </WelcomeSection>
          {storedUser && (
            <TicketsContainer>
              {savedLotteryTickets && savedLotteryTickets.length > 0 && (
                <div
                  style={{
                    overflowY: 'auto',
                    height: '170px',
                    paddingRight: '10px',
                  }}
                >
                  <TicketsHeader>
                    Your Saved Mega Millions Lottery Numbers
                  </TicketsHeader>
                  {savedLotteryTickets.map(ticketInfo => {
                    return (
                      <SavedNumber
                        key={ticketInfo.ticket_id}
                        payload={ticketInfo}
                        isReadOnly={true}
                      />
                    )
                  })}
                </div>
              )}

              {savedPowerballTickets && savedPowerballTickets.length > 0 && (
                <div
                  style={{
                    overflowY: 'auto',
                    height: '170px',
                    paddingRight: '10px',
                  }}
                >
                  <TicketsHeader>
                    Your Saved Power Ball Lottery Numbers
                  </TicketsHeader>
                  {savedPowerballTickets.map(ticketInfo => {
                    return (
                      <SavedNumber
                        key={ticketInfo.ticket_id}
                        payload={ticketInfo}
                        isPowerBall={true}
                        isReadOnly={true}
                      />
                    )
                  })}
                </div>
              )}
            </TicketsContainer>
          )}
        </Row>
      </BackgroundSection>
      <Subheading
        style={{ zIndex: 10, marginTop: isDesktop ? '0rem' : '5rem' }}
      >
        Key Features
      </Subheading>
      <Features>
        {featuresA.map((feature, index) => (
          <Feature
            key={index}
            icon={featureAIcons[index]}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </Features>
      <Features style={{ marginTop: !isDesktop && '0' }}>
        {featuresB.map((feature, index) => (
          <Feature
            key={index}
            icon={featureBIcons[index]}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </Features>
      <CommunitySection>
        <Subheading style={{ color: 'white' }}>
          Join Our Lottery Community
        </Subheading>
        <Text
          style={{
            color: 'white',
            width: isDesktop ? '35%' : '100%',
            marginTop: '2rem',
          }}
        >
          Sign up today and become a part of our growing community of lottery
          enthusiasts. Enjoy exclusive features and stay ahead with the latest
          lottery updates.
        </Text>
        <Button
          variant='contained'
          sx={{
            textTransform: 'none',
            color: 'white',
            backgroundColor: '#D8AA09',
            marginTop: '2rem',
          }}
          onClick={() => navigate('/sign-up')}
        >
          Sign Up
        </Button>
      </CommunitySection>
      <Subheading>How It Works?</Subheading>
      <Features>
        {stepsA.map((step, index) => (
          <Step
            key={index}
            icon={stepsAIcons[index]}
            title={step.title}
            description={step.description}
            ball={stepsABalls[index]}
          />
        ))}
      </Features>
      <Features>
        {stepsB.map((step, index) => (
          <Step
            key={index}
            icon={stepsBIcons[index]}
            title={step.title}
            description={step.description}
            ball={stepsBBalls[index]}
          />
        ))}
      </Features>
      <Footer>
        <FooterTextContainer>
          <Subheading style={{ alignSelf: 'flex-start', color: 'white' }}>
            Why Wait? Start Winning Today!
          </Subheading>
          <Text style={{ color: 'white', marginTop: '2rem' }}>
            Don't miss out on your chance to stay updated with the latest
            lottery results. Join MilliCheck now and transform your lottery
            experience.
          </Text>
          <Subheading
            style={{
              alignSelf: isDesktop && 'flex-start',
              color: '#D8AA09',
              marginTop: '2rem',
              fontSize: !isDesktop && '25px',
            }}
          >
            Stay Lucky, Stay Informed!
          </Subheading>
        </FooterTextContainer>
        <img
          style={{ width: !isDesktop && '100%', height: !isDesktop && '100%' }}
          src={FooterIllustration}
          alt='Footer Illustration'
        />
      </Footer>
    </Main>
  )
}

export default Home
