import Process from '../assets/Process.png'
import { Heading, Main, Text, TextSection } from './styles/LotteryTime'

const LotteryTime = () => {
  return (
    <Main>
      <Heading>Your saved Lottery Numbers</Heading>
      <TextSection>
        <img
          style={{ height: '100px', width: '100px' }}
          src={Process}
          alt='img'
        />
        <Text>
          Your saved numbers are currently being checked against the latest draw
          results.
          <br />
          <br />
          <br /> We will be sending you an email with your results soon!
        </Text>
      </TextSection>
    </Main>
  )
}

export default LotteryTime
