import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 5000,
})

axiosInstance.interceptors.request.use(
  config => {
    const token = JSON.parse(localStorage.getItem('tokens'))
    config.headers['MilliCheckAuth'] = `Authorization ${token.access}`
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('tokens')
      localStorage.removeItem('user')
      window.location.reload()
    }

    return Promise.reject(error)
  },
)

const api = {
  get: async (url, params) => {
    try {
      const response = await axiosInstance.get(url, { params })
      return response.data
    } catch (error) {
      throw error
    }
  },

  post: async (url, data) => {
    try {
      const response = await axiosInstance.post(url, data)
      return response.data
    } catch (error) {
      return { message: error?.response?.data?.error }
    }
  },

  put: async (url, data) => {
    try {
      const response = await axiosInstance.put(url, data)
      return response.data
    } catch (error) {
      throw error
    }
  },
  delete: async url => {
    try {
      const response = await axiosInstance.delete(url)
      return response.data
    } catch (error) {
      throw error
    }
  },
}

export default api
