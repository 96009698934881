import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'
import BallsBackground from '../../assets/new-bg.webp'

export const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const WelcomeSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem ${isDesktop ? '4rem' : '2rem'};
  width: ${isDesktop ? '30%' : '90'};
  text-align: ${!isDesktop && 'center'};
  margin-top: 5rem;
`

export const BackgroundSection = styled.div`
  background-image: url(${BallsBackground});
  background-size: cover;
  background-position: 0px 0px;
`

export const Heading = styled.span`
  font-size: ${isDesktop ? '45px' : '35px'};
  font-weight: bold;
`

export const ItalicText = styled.span`
  font-style: italic;
  font-size: ${isDesktop ? '35px' : '18px'};
  font-weight: 500;
  margin-top: 2rem;
`

export const Text = styled.span`
  font-size: 16px;
`

export const Subheading = styled.span`
  font-size: ${isDesktop ? '35px' : '30px'};
  font-weight: bold;
  align-self: center;
`

export const Features = styled.div`
  display: flex;
  flex-direction: ${!isDesktop && 'column'};
  justify-content: space-between;
  padding: ${isDesktop ? '2rem 4rem' : '2rem'};
  margin-top: 2rem;
  gap: ${!isDesktop && '2rem'};
  z-index: 10;
`

export const CommunitySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #6d6d6d;
  margin: 5rem ${isDesktop ? '4rem' : '2rem'};
  border-radius: 20px;
  padding: ${isDesktop ? '5rem' : '2rem'};
  text-align: center;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: ${!isDesktop && 'column'};
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 0 ${isDesktop ? '4rem' : '2rem'};
  margin-top: 5rem;
`

export const FooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isDesktop ? '32%' : '100%'};
  text-align: ${!isDesktop && 'center'};
  margin-top: ${!isDesktop && '5rem'};
`

export const Row = styled.div`
  display: flex;
  flex-direction: ${!isDesktop && 'column'};
  justify-content: space-between;
`

export const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 5rem;
  padding: 1rem ${isDesktop ? '2rem' : '2rem'};
  background-color: ${isDesktop && ' rgba(255, 255, 255, 0.85)'};
  border-radius: 20px;
  drop-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-right: ${isDesktop && '2rem'};
  height: fit-content;
  max-height: 500px;
  overflow-y: auto;
`

export const TicketsHeader = styled.span`
  font-size: 20px;
  font-weight: bold;
`
