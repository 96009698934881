import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: ${isDesktop ? '100%' : '80%'};
  margin-left: 3rem;
`

export const Heading = styled.span`
  font-size: ${isDesktop ? '30px' : '20px'};
  font-weight: bold;
`

export const TextSection = styled.div`
  display: flex;
  gap: 2rem;
  background-color: white;
  border-radius: 10px;
  padding: 2rem 1rem;
`

export const Text = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: #6d6d6d;
`
