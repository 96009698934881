import Task from '../assets/Task.png'
import { Heading, Main, Text } from './styles/NoTickets'

const NoTickets = () => {
  return (
    <Main>
      <Heading>No tickets were saved for this draw. </Heading>
      <img style={{ height: '100px', width: '100px' }} src={Task} alt='Img' />
      <Text>Save your numbers now to participate in the next draw.</Text>
    </Main>
  )
}

export default NoTickets
