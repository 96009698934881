import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${isDesktop ? '50%' : '80%'};
  background-color: white;
  border-radius: 10px;
  padding: 5%;
`

export const Heading = styled.span`
  font-size: ${isDesktop ? '45px' : '30px'};
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 2rem;
`

export const Text = styled.span`
  font-size: 16px;
  color: #6d6d6d;
  margin: ${!isDesktop && '0 2rem'};
  text-align: center;
  margin-top: ${!isDesktop ? '1rem' : '2rem'};
`
