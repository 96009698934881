import { isDesktop } from 'react-device-detect'
import { Description, Main, Title } from './styles/Feature'

const Feature = ({ icon, title, description }) => {
  return (
    <Main>
      <img
        style={{
          height: isDesktop ? '100px' : '60px',
          width: isDesktop ? '100px' : '60px',
        }}
        src={icon}
        alt='Icon'
      />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Main>
  )
}

export default Feature
