import { InputBase } from '@mui/material'
import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${isDesktop ? '50%' : '80%'};
  background-color: white;
  border-radius: 10px;
  padding: 5%;
  margin: 5% 0;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isDesktop ? '60%' : '80%'};
`

export const Heading = styled.span`
  font-size: ${isDesktop ? '45px' : '30px'};
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 3rem;
`

export const Input = styled(InputBase)`
  border: 1px solid #6d6d6d;
  border-radius: 4px;
  padding: 6px 20px;
`

export const LinkText = styled.span`
  font-size: 16px;
  color: #002cb6;
  cursor: pointer;
`

export const CheckboxLabel = styled.span`
  font-size: 16px;
  color: #6d6d6d;
`

export const Text = styled.span`
  font-size: 16px;
  margin-top: 2rem;
`

export const ErrorText = styled.span`
  font-size: 16px;
  color: red;
  font-weight: 500;
`
