import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { isDesktop } from 'react-device-detect'
import Logo from '../../assets/Logo Black.webp'
import EmailSentIcon from '../../assets/EmailSentIcon.png'
import { useEffect } from 'react'
import { Main, Text } from './styles'

const AccountVerification = () => {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'MilliCheck | Verify your Email'
  })

  return (
    <Main>
      <img
        style={{
          margin: '3rem 0',
          height: !isDesktop && '50px',
          width: !isDesktop && '190px',
        }}
        src={Logo}
        alt='Logo'
      />
      <img
        style={{ margin: isDesktop ? '3rem 0' : ' 0' }}
        src={EmailSentIcon}
        alt='Logo'
      />
      <Text>
        A verification email has been sent to your email address. Please check
        your inbox to activate your account
      </Text>
      <Button
        variant='contained'
        sx={{
          textTransform: 'none',
          backgroundColor: '#002CB6',
          width: isDesktop ? '40%' : '90%',
          color: 'white',
          marginTop: '3rem',

          '&:hover': {
            backgroundColor: '#0038E5',
          },
        }}
        onClick={() => navigate('/sign-in')}
      >
        Return to login
      </Button>
    </Main>
  )
}

export default AccountVerification
