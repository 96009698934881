import React from 'react'
import { isDesktop } from 'react-device-detect'
import { ColumnContainer, Description, Main, Title } from './styles/Step'

const Step = ({ icon, title, description, ball }) => {
  return (
    <Main>
      <img
        src={ball}
        alt='Ball'
        style={{
          position: 'absolute',
          top: isDesktop ? '-45px' : '-25px',
          right: isDesktop && '-25px',
          left: !isDesktop && '-25px',
          width: !isDesktop && '50px',
          height: !isDesktop && '50px',
        }}
      />
      <img style={{ width: '170px', height: '150px' }} src={icon} alt='icon' />
      <ColumnContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ColumnContainer>
    </Main>
  )
}

export default Step
