/* eslint-disable */
import { useLocation, useRoutes } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import SignIn from "./routes/sign-in";
import SignUp from "./routes/sign-up";
import ForgotPassword from "./routes/forgot-password";
import ResetPassword from "./routes/reset-password";
import Verification from "./routes/verification";
import EmailAcitvation from "./routes/email-activation";
import AccountVerification from "./routes/account-verification";
import Home from "./routes/home";
import Powerball from "./routes/powerball";
import Megamillions from "./routes/megamilllions";
import Background from "./assets/Background.png";
import { isDesktop } from "react-device-detect";

const Main = styled.div`
  min-width: 90vw;
  min-height: 100vh;
  background-color: #ebf7f7;
  background-image: ${(props) =>
    props.showBackground ? `url(${Background})` : "none"};
  background-size: ${isDesktop ? "cover" : "contain"};
  background-position-y: ${isDesktop ? "-150px" : "0"};
  background-repeat: no-repeat;
`;

function App() {
  const [user, setUser] = useState(localStorage.getItem("user"));

  const location = useLocation();
  const navigate = useNavigate();

  const authRoutes = [
    "/forgot-password",
    "/verification",
    "/account-verification",
    "/sign-in",
    "/sign-up",
    "/",
  ];
  const isAuthRoute =
    authRoutes.includes(location.pathname) ||
    location.pathname.startsWith("/user/password-reset") ||
    location.pathname.startsWith("/user/activate");

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    setUser(storedUser);

    if (!storedUser && !isAuthRoute) {
      navigate("/sign-in");
    }

    if (storedUser && isAuthRoute && !location.pathname === "/") {
      navigate("/");
    }
  }, [location]);

  const routes = [
    {
      path: "/sign-in",
      caseSensitive: true,
      element: <SignIn />,
    },
    {
      path: "/sign-up",
      caseSensitive: true,
      element: <SignUp />,
    },
    {
      path: "/forgot-password",
      caseSensitive: true,
      element: <ForgotPassword />,
    },
    {
      path: "/user/password-reset/:uid/:token",
      caseSensitive: true,
      element: <ResetPassword />,
    },
    {
      path: "/verification",
      caseSensitive: true,
      element: <Verification />,
    },
    {
      path: "/",
      caseSensitive: true,
      element: <Home />,
    },
    {
      path: "/powerball",
      caseSensitive: true,
      element: <Powerball />,
    },
    {
      path: "/megamillions",
      caseSensitive: true,
      element: <Megamillions />,
    },
    {
      path: "/user/activate/:uid/:token",
      caseSensitive: true,
      element: <EmailAcitvation />,
    },
    {
      path: "/account-verification",
      caseSensitive: true,
      element: <AccountVerification />,
    },
  ];

  const route = useRoutes(routes);

  return (
    <Main
      style={{
        display: isAuthRoute && "flex",
        justifyContent: isAuthRoute && "center",
        alignItems: isAuthRoute && "center",
      }}
      showBackground={location.pathname !== "/"}
    >
      {route}
    </Main>
  );
}

export default App;
