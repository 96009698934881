export const featuresA = [
  {
    title: 'Instant Lottery Results',
    description:
      'Get real-time updates on the latest lottery results. No more waiting or manual checking  we bring the results to you instantly!',
  },
  {
    title: 'Personalized Lottery Checker',
    description:
      'Input your lottery numbers and let our application do the rest. Track your numbers across multiple draws with ease.',
  },
  {
    title: 'Winning Notifications',
    description:
      'Be the first to know when you win! Our system automatically checks your numbers against the latest draws and notifies you of any winnings.',
  },
]

export const featuresB = [
  {
    title: 'User-Friendly Interface',
    description:
      "Navigate our intuitive platform effortlessly. Whether you're a tech-savvy user or a beginner, our design ensures a smooth experience.",
  },
  {
    title: 'Comprehensive Draw History',
    description:
      "Access historical data to see past lottery results and analyze patterns. Knowledge is power, and we've got all the data you need.",
  },
  {
    title: 'Secure and Confidential',
    description:
      'We prioritize your privacy and the security of your data. Our platform uses advanced encryption to ensure your information remains safe and confidential.',
  },
]

export const stepsA = [
  {
    title: 'Sign Up',
    description: 'Create a free account in seconds.',
  },
  {
    title: 'Enter Your Lottery Numbers',
    description: 'Input your lottery numbers into our secure system.',
  },
]

export const stepsB = [
  {
    title: 'Check Lottery Results',
    description: 'Create a free account in seconds.',
  },
  {
    title: 'Track Your Winnings',
    description:
      'Receive notifications for any winnings directly to your account.',
  },
]
