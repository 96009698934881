import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${isDesktop ? '60px' : '35px'};
  height: ${isDesktop ? '60px' : '35px'};
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) inset;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3) inset;
`

export const Number = styled.span`
  font-size: ${isDesktop ? '25px' : '15px'};
  font-weight: bold;
`
