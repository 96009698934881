import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { isDesktop } from 'react-device-detect'
import { Heading, Main, Text } from './styles/PasswordChanged'

const PasswordChanged = ({ logo, icon, bg }) => {
  const navigate = useNavigate()

  return (
    <Main bg={bg}>
      <img style={{ margin: '3rem 0' }} src={logo} alt='Logo' />
      {!isDesktop && <Heading>Password Changed</Heading>}
      <img style={{ margin: '3rem 0' }} src={icon} alt='Logo' />
      <Text>Your password has been changed successfully.</Text>
      <Button
        variant='contained'
        sx={{
          textTransform: 'none',
          backgroundColor: '#002CB6',
          width: isDesktop ? '40%' : '80%',
          color: 'white',
          marginTop: '3rem',

          '&:hover': {
            backgroundColor: '#0038E5',
          },
        }}
        onClick={() => navigate('/sign-in')}
      >
        Return to login
      </Button>
    </Main>
  )
}

export default PasswordChanged
