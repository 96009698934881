import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${!isDesktop && 'column'};
  align-items: center;
  background-color: white;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05);
  width: ${isDesktop ? '45%' : '85%'};
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${!isDesktop && 'center'};
  text-align: ${!isDesktop && 'center'};
`

export const Title = styled.div`
  font-size: ${isDesktop ? '25px' : '18px'};
  font-weight: bold;
`

export const Description = styled.div`
  font-size: 16px;
`
