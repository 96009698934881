/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { Button, Modal, Tooltip } from '@mui/material'
import { deleteTicket, editTicket } from '../api-handlers/megamillions'
import SnackbarStyled from '../components/snackbar'
import { useLocation } from 'react-router-dom'
import {
  deletePowerballTicket,
  editPowerballTicket,
} from '../api-handlers/powerball'
import {
  DigitInputField,
  ErrorText,
  Main,
  ModalContainer,
  Row,
  StyledDeleteIcon,
  StyledEditIcon,
  StyledInfoIcon,
} from './styles/SavedNumber'

const SavedNumber = ({
  payload,
  reload,
  setReload,
  isPowerBall,
  isReadOnly,
}) => {
  const [isInputValid, setIsInputValid] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ])
  const [error, setError] = useState('')
  const [lotteryNumber, setLotteryNumber] = useState([
    payload.first_number,
    payload.second_number,
    payload.third_number,
    payload.fourth_number,
    payload.fifth_number,
  ])
  const [isEdit, setIsEdit] = useState(false)
  const [toast, setToast] = useState({
    open: false,
    message: 'Ticket updated successfully',
    severity: 'success',
  })
  const [open, setOpen] = useState(false)

  const inputRefs = useRef([])

  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/powerball' || isPowerBall) {
      setLotteryNumber([...lotteryNumber, payload.power_ball])
    } else {
      setLotteryNumber([...lotteryNumber, payload.mega_ball])
    }
  }, [])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const validateInput = (value, index) => {
    const num = parseInt(value, 10)
    let valid = true

    if (index === lotteryNumber.length - 1) {
      valid = num >= 1 && num <= 25
    } else {
      valid = num >= 1 && num <= 70
    }

    return valid
  }

  const validateNumberOnChange = arr => {
    for (let i = 0; i < 5; i++) {
      if (location.pathname === '/powerball') {
        if (arr[i] !== '' && (arr[i] < 1 || arr[i] > 69)) {
          setError('Numbers should be between 1 and 69')
        }
      } else {
        if (arr[i] !== '' && (arr[i] < 1 || arr[i] > 70)) {
          setError('Numbers should be between 1 and 70')
        }
      }
    }

    if (location.pathname === '/powerball') {
      if (arr[5] !== '' && (arr[5] < 1 || arr[5] > 26)) {
        setError('Power Ball number should be between 1 and 26')
      }
    } else {
      if (arr[5] !== '' && (arr[5] < 1 || arr[5] > 25)) {
        setError('Mega Ball number should be between 1 and 25')
      }
    }
  }

  const validateLotteryNumber = arr => {
    for (let i = 0; i < 5; i++) {
      if (location.pathname === '/powerball') {
        if (arr[i] !== '' && (arr[i] < 1 || arr[i] > 69)) {
          setError('Numbers should be between 1 and 69')
          return false
        }
      } else {
        if (arr[i] !== '' && (arr[i] < 1 || arr[i] > 70)) {
          setError('Numbers should be between 1 and 70')
          return false
        }
      }
    }

    if (location.pathname === '/powerball') {
      if (arr[5] !== '' && (arr[5] < 1 || arr[5] > 26)) {
        setError('Power Ball number should be between 1 and 26')
        return false
      }
    } else {
      if (arr[5] !== '' && (arr[5] < 1 || arr[5] > 25)) {
        setError('Mega Ball number should be between 1 and 25')
        return false
      }
    }

    return true
  }

  const handleInputChange = (event, index) => {
    setError('')
    let inputDigit = event.target.value

    if (inputDigit.length === 2 && index < lotteryNumber.length - 1) {
      // Move focus to the next input field if there are two digits
      inputRefs.current[index + 1]?.focus()
    }

    if (inputDigit > 99) return false

    inputDigit = inputDigit.replace(/^0+(?=\d)/, '')

    const updatedLotteryNumber = [...lotteryNumber]
    updatedLotteryNumber[index] = inputDigit

    const updatedIsInputValid = Array(updatedLotteryNumber.length).fill(true)

    const numberOccurrences = {}

    updatedLotteryNumber.forEach((num, idx) => {
      if (num !== '' && idx !== updatedLotteryNumber.length - 1) {
        const normalizedNum = String(num).replace(/^0+(?=\d)/, '')

        if (numberOccurrences[normalizedNum]) {
          numberOccurrences[normalizedNum].push(idx)
        } else {
          numberOccurrences[normalizedNum] = [idx]
        }

        updatedIsInputValid[idx] = validateInput(normalizedNum, idx)
      }
    })

    Object.values(numberOccurrences).forEach(indices => {
      if (indices.length > 1) {
        indices.forEach(i => {
          updatedIsInputValid[i] = false
        })
      }
    })

    setLotteryNumber(updatedLotteryNumber)
    setIsInputValid(updatedIsInputValid)

    const hasDuplicates = Object.values(numberOccurrences).some(
      indices => indices.length > 1,
    )
    if (hasDuplicates) setError('Duplicate values are not allowed.')
    validateNumberOnChange(updatedLotteryNumber)
  }

  const handleConfirm = async () => {
    const isValid = validateLotteryNumber(lotteryNumber)

    if (isValid) {
      if (location.pathname === '/powerball') {
        const ticketInfo = {
          megaball_lottery: payload.powerball_lottery,
          first_number: lotteryNumber[0],
          second_number: lotteryNumber[1],
          third_number: lotteryNumber[2],
          fourth_number: lotteryNumber[3],
          fifth_number: lotteryNumber[4],
          power_ball: parseInt(lotteryNumber[5]),
        }

        const response = await editPowerballTicket(
          JSON.stringify(ticketInfo),
          payload.ticket_id,
        )

        setToast({
          ...toast,
          open: true,
          message: response?.message,
          severity: response?.message.includes('success') ? 'success' : 'info',
        })
        setIsEdit(false)
      } else {
        const ticketInfo = {
          megaball_lottery: payload.megaball_lottery,
          first_number: lotteryNumber[0],
          second_number: lotteryNumber[1],
          third_number: lotteryNumber[2],
          fourth_number: lotteryNumber[3],
          fifth_number: lotteryNumber[4],
          mega_ball: parseInt(lotteryNumber[5]),
        }

        const response = await editTicket(
          JSON.stringify(ticketInfo),
          payload.ticket_id,
        )

        setToast({
          ...toast,
          open: true,
          message: response?.message,
          severity: response?.message.includes('success') ? 'success' : 'info',
        })
        setIsEdit(false)
      }
    }
  }

  const handleDelete = async () => {
    if (location.pathname === '/powerball') {
      await deletePowerballTicket(payload.ticket_id)
    } else {
      await deleteTicket(payload.ticket_id)
    }
    setToast({
      ...toast,
      open: true,
      message: 'Ticket deleted successfully.',
      severity: 'info',
    })
    setReload(!reload)
  }

  const handleSnackbarClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToast({ ...toast, open: false })
  }

  return (
    <>
      <SnackbarStyled
        message={toast.message}
        onClose={handleSnackbarClose}
        severity={toast.severity}
        open={toast.open}
      />
      <Main>
        <Row>
          {lotteryNumber.map((digit, index) => (
            <DigitInputField
              style={{
                color:
                  index === lotteryNumber.length - 1 && isPowerBall
                    ? 'white'
                    : 'black',
                backgroundColor:
                  index === lotteryNumber.length - 1
                    ? isPowerBall
                      ? '#C8102E'
                      : '#FFEFB5'
                    : 'white',
                border: isInputValid[index]
                  ? index === lotteryNumber.length - 1
                    ? '1px solid #D8AA09'
                    : '1px solid #6D6D6D'
                  : '1px solid red',
              }}
              key={index}
              ref={el => (inputRefs.current[index] = el)}
              disabled={!isEdit}
              value={digit}
              inputMode='numeric'
              inputProps={{ maxLength: 2 }}
              placeholder={index === lotteryNumber.length - 1 ? '1-25' : '1-70'}
              onChange={e => handleInputChange(e, index)}
              onKeyDown={e => {
                if (
                  !/^[0-9]$/.test(e.key) && // Allow digits
                  e.key !== 'Backspace' && // Allow backspace
                  e.key !== 'Delete' && // Allow delete
                  e.key !== 'ArrowLeft' && // Allow left arrow
                  e.key !== 'ArrowRight' // Allow right arrow
                ) {
                  e.preventDefault() // Prevent any other input
                }
              }}
            />
          ))}
          {!isReadOnly && (
            <Row style={{ marginTop: 0, marginLeft: '1rem' }}>
              <Tooltip
                title={`Your lottery was added on: ${payload.stored_date}`}
              >
                <StyledInfoIcon />
              </Tooltip>
              {isEdit ? (
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={handleConfirm}
                >
                  <CheckRoundedIcon
                    style={{ color: 'green', cursor: 'pointer' }}
                  />
                </div>
              ) : (
                <StyledEditIcon onClick={() => setIsEdit(true)} />
              )}
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={handleOpen}
              >
                <StyledDeleteIcon />
              </div>
            </Row>
          )}
        </Row>
        {error && <ErrorText>{error}</ErrorText>}
        <Modal open={open} onClose={handleClose} disableAutoFocus>
          <ModalContainer>
            <span>Are you sure you want to delete this ticket?</span>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Button onClick={handleClose}>No</Button>
              <Button onClick={handleDelete}>Yes</Button>
            </Row>
          </ModalContainer>
        </Modal>
      </Main>
    </>
  )
}

export default SavedNumber
