import { Avatar, Button, Divider, Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import { useNavigate } from 'react-router-dom'
import { logout } from '../api-handlers/auth'
import { isDesktop } from 'react-device-detect'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { Container, Main, Nav, Route } from './styles/Header'

const Header = ({ logo }) => {
  const navRoutes = [
    { name: 'Home', route: '/' },
    { name: 'Power Ball Lottery Board', route: '/powerball' },
    { name: 'Mega Millions Lottery board', route: '/megamillions' },
  ]

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const navigate = useNavigate()

  let user = localStorage.getItem('user')
  user = JSON.parse(user)

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName?.charAt(0).toUpperCase()
    const lastInitial = lastName?.charAt(0).toUpperCase()
    return `${firstInitial}${lastInitial}`
  }

  const handleClick = event => {
    if (anchorEl) {
      handleClose()
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    try {
      await logout()
      localStorage.removeItem('user')
      navigate('/sign-in')
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Main>
      <img
        style={{
          height: isDesktop ? '60px' : '50px',
          width: isDesktop ? '210px' : '190px',
        }}
        src={logo}
        alt='logo'
      />
      {isDesktop &&
        (user ? (
          <Nav>
            {navRoutes.map(route => (
              <Route
                style={{
                  color:
                    window.location.pathname === route.route
                      ? 'white'
                      : window.location.pathname === '/'
                      ? 'black'
                      : 'white',
                  textDecoration:
                    window.location.pathname === route.route
                      ? 'underline'
                      : 'none',
                  textUnderlineOffset: '0.5rem',
                }}
                key={route.name}
                onClick={() => navigate(route.route)}
              >
                {route.name}
              </Route>
            ))}
          </Nav>
        ) : (
          <div></div>
        ))}
      {user ? (
        isDesktop ? (
          <Container>
            <Avatar
              sx={{
                width: 50,
                height: 50,
                fontSize: '1rem',
                padding: '1px',
                marginLeft: !isDesktop && '3rem',
                backgroundColor: '#D8AA09',
              }}
            >
              {getInitials(user?.first_name, user?.last_name)}
            </Avatar>
            <Button
              sx={{ minWidth: 'unset', padding: 0 }}
              onClick={handleLogout}
            >
              <LogoutRoundedIcon sx={{ color: 'white' }} />
            </Button>
          </Container>
        ) : (
          <div onClick={handleClick}>
            <MenuRoundedIcon />
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {navRoutes.map(route => {
                return (
                  <MenuItem
                    key={route.name}
                    disableRipple
                    onClick={() => {
                      handleClose()
                      navigate(route.route)
                    }}
                  >
                    {route.name}
                  </MenuItem>
                )
              })}
              <Divider sx={{ my: 0.5 }} />
              <MenuItem disableRipple onClick={handleClose}>
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    fontSize: '1rem',
                    padding: '1px',
                    backgroundColor: '#D8AA09',
                  }}
                >
                  {getInitials(user?.first_name, user?.last_name)}
                </Avatar>
                <span style={{ marginLeft: '1rem' }}>
                  {user?.first_name} {user?.last_name}
                </span>
              </MenuItem>
              <MenuItem
                disableRipple
                onClick={() => {
                  handleLogout()
                  handleClose()
                }}
              >
                <LogoutRoundedIcon sx={{ color: 'black' }} />
                <span style={{ marginLeft: '1rem' }}>Logout</span>
              </MenuItem>
            </Menu>
          </div>
        )
      ) : (
        <Button
          sx={{
            textTransform: 'none',
            color: 'black',
            border: '2px solid black',

            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
          }}
          onClick={() => {
            if (user) {
              localStorage.removeItem('user')
            }
            navigate('/sign-in')
          }}
        >
          <PersonRoundedIcon style={{ marginRight: '0.5rem' }} />
          {!user ? 'Login' : 'Logout'}
        </Button>
      )}
    </Main>
  )
}

export default Header
