import { isDesktop } from 'react-device-detect'
import styled from 'styled-components'

export const Main = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const WinningNumbersSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: ${isDesktop && '100%'};
  border-radius: 10px;
  background: linear-gradient(to bottom, #dcdcdc, #f0f0f0);
`

export const Subheading = styled.span`
  font-size: ${isDesktop ? '30px' : '20px'};
  font-weight: bold;
  color: white;
`

export const Heading = styled.span`
  font-size: ${isDesktop ? '20px' : '15px'};
  font-weight: bold;
`

export const NumbersRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`

export const Text = styled.span`
  font-size: 16px;
  color: white;
`

export const FutureSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Timer = styled.div`
  display: flex;
  gap: ${isDesktop ? '2rem' : '1rem'};
  margin-top: 1rem;
  background-color: black;
  border-radius: 10px;
  padding: 0 2rem 1rem 2rem;
  width: fit-content;
`

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TimeBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px solid black;
  border-radius: 10px;
`

export const JackkpotContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  margin-top: 1rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: ${!isDesktop && 'column'};
  gap: ${isDesktop ? '4rem' : '2rem'};
  margin: 0 ${isDesktop ? '3rem' : '2rem'};
`

export const LotteryNumbers = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isDesktop && '100%'};
`

export const InputFieldsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
`

export const DigitInputField = styled.input`
  height: ${isDesktop ? '40px' : '30px'};
  width: ${isDesktop ? '40px' : '30px'};
  font-size: ${isDesktop ? '16px' : '12px'};
  text-align: center;
  padding: 0;
  border-radius: 5px;
  border: 1px solid #6d6d6d;
`

export const ErrorText = styled.span`
  font-size: 16px;
  color: red;
  margin-top: 15px;
  font-weight: 500;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 2%;
  border-radius: 10px;
`
