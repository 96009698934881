import { useEffect, useState } from 'react'
import Logo from '../../assets/Logo Black.webp'
import { Button } from '@mui/material'
import EmailSent from '../../components/EmailSent'
import EmailSentIcon from '../../assets/EmailSentIcon.png'
import { isDesktop } from 'react-device-detect'
import BG from '../../assets/BG.png'
import { forgotPassword } from '../../api-handlers/auth'
import { ErrorText, FormContainer, Heading, Input, Main, Text } from './styles'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [showEmailSent, setShowEmailSent] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    document.title = 'MilliCheck | Reset your Password'
  })

  const handleContinueClick = async () => {
    try {
      const response = await forgotPassword(email)
      if (response.status === 200) {
        setShowEmailSent(true)
      } else {
        setError(response.data.error.email[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      {!showEmailSent ? (
        <Main>
          <img
            style={{
              height: !isDesktop && '50px',
              width: !isDesktop && '190px',
            }}
            src={Logo}
            alt='Logo'
          />
          <FormContainer>
            <Heading>Forgot Password</Heading>
            <Text>
              Enter the email address you used when you joined and we’ll send
              you instructions to reset your password.
            </Text>
            <Input
              style={{ margin: isDesktop ? '3rem 0 1rem 0' : '2rem 0 2rem 0' }}
              placeholder='Email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            {error && <ErrorText>{error}</ErrorText>}
          </FormContainer>
          <Button
            variant='contained'
            disabled={email.trim() === ''}
            sx={{
              textTransform: 'none',
              backgroundColor: '#002CB6',
              width: isDesktop ? '60%' : '80%',
              color: 'white',
              marginTop: '1rem',

              '&:hover': {
                backgroundColor: '#0038E5',
              },

              '&:disabled': {
                backgroundColor: '#002CB6',
                color: 'white',
                opacity: 0.5,
              },
            }}
            onClick={handleContinueClick}
          >
            Continue
          </Button>
        </Main>
      ) : (
        <EmailSent logo={Logo} icon={EmailSentIcon} bg={BG} />
      )}
    </>
  )
}

export default ForgotPassword
